<template>
  <div class="app">
    <InformationRequest />
  </div>
</template>

<script>
// @ is an alias to /src
import InformationRequest from '@/components/InformationRequest.vue'

export default {
  name: 'Home',
  components: {
    InformationRequest
  }
}
</script>
