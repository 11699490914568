<template>
  <div class="informationrequest">
    <h1>Work in progress</h1>
  </div>
</template>

<script>
export default {
  name: 'InformationRequest'
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
</style>
