import Vue from 'vue'
import VueRouter from 'vue-router'
import InformationRequest from '../views/InformationRequest.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/informationrequest',
    name: 'Information Request',
    component: InformationRequest
  },
  {
    path: '/',
    redirect: { name: 'Information Request' }
  },
]

const router = new VueRouter({
  routes
})

export default router
